import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from '../components/header/header.jsx';
import Loading from '../../src/utils/recycle-components/Loading.jsx';
import loginAPI from '../api/authentication/login.js';
// import { ProfileContextProvider } from '../contexts/ProfileContext.jsx';
// import classnames from 'classnames';

import './fulllayout.scss';

class Fulllayout extends React.Component {
  /*--------------------------------------------------------------------------------*/
  /*Change the layout settings [HEADER,SIDEBAR && DARK LAYOUT] from here            */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    global.tokenString = '';
    this.updateDimensions = this.updateDimensions.bind(this);
    this.state = {
      loadingLoginStatus: true,
      profileData: {
        email: '',
        id: '',
        roles: '',
        status: '',
        userIntercom: { user_id: '', email: '', name: '' },
      },
      profileDetail: {},
      userInfo: {},
      isNavOpen: false,
      width: window.innerWidth,
      settings: [
        {
          theme: 'light',
          layout: 'vertical',
          dir: 'ltr',
          // sidebartype: 'full',
          sidebarpos: 'fixed',
          headerpos: 'fixed',
          boxed: 'full',
          navbarbg: 'primaryColor',
          sidebarbg: 'primaryColor',
          logobg: 'primaryColor',
        },
      ],
    };

    this.props.history.listen((location, action) => {
      if (window.innerWidth < 767 && document.getElementById('main-wrapper')) {
        if (
          document
            .getElementById('main-wrapper')
            .className.indexOf('show-sidebar') !== -1
        ) {
          document
            .getElementById('main-wrapper')
            .classList.toggle('show-sidebar');
        }
      }
    });
    this.fetchData = this.fetchData.bind(this);
    this.reloadData = this.reloadData.bind(this);
    this.toggleMobileNav = this.toggleMobileNav.bind(this);
  }

  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggleMobileNav() {
    this.setState({
      isNavOpen: !this.state.isNavOpen,
    });
  }

  fetchData = async (cb) => {
    const {
      match: { path },
      location: { pathname },
    } = this.props;
    var profile = {};
    let res = await loginAPI.getUserDetail(path, pathname);
    if (res && res.data && res.data.roles === 'user') {
      let res1 = await loginAPI.getUserProfile();
      let res2 = await loginAPI.getUserInfo();
      let fullname = res2.data.fName + ' ' + res2.data.lName;
      let iemail = res.data.email;
      let clientId = res1.data.id;

      let interComUsr = {
        user_id: clientId,
        email: iemail,
        name: fullname,
      };
      await this.setState({
        userInfo: res2.data,
        profileData: res && res.data,
        loadingLoginStatus: false,
        profileDetail: res1.data,
        userIntercom: interComUsr,
      });
      if (cb) cb();
    } else {
      await this.setState({
        profileData: res && res.data,
        loadingLoginStatus: false,
      });
    }
  };

  reloadData(cb) {
    this.fetchData(cb);
  }

  /*--------------------------------------------------------------------------------*/
  /*Life Cycle Hook, Applies when loading or resizing App                           */
  /*--------------------------------------------------------------------------------*/
  componentDidMount() {
    this.fetchData();
    window.addEventListener('load', this.updateDimensions);
    window.addEventListener('resize', this.updateDimensions);
  }

  /*--------------------------------------------------------------------------------*/
  /*Function that handles sidebar, changes when resizing App                        */
  /*--------------------------------------------------------------------------------*/
  updateDimensions() {
    if (window.innerWidth === this.state.width) return;

    let element = document.getElementById('main-wrapper');
    this.setState({
      width: window.innerWidth,
    });
    switch (this.state.settings[0].sidebartype) {
      case 'full':
      case 'iconbar':
        if (this.state.width < 1170) {
          element.setAttribute('data-sidebartype', 'mini-sidebar');
          element.classList.add('mini-sidebar');
        } else {
          element.setAttribute(
            'data-sidebartype',
            this.state.settings[0].sidebartype
          );
          element.classList.remove('mini-sidebar');
        }
        break;

      case 'overlay':
        if (this.state.width < 767) {
          element.setAttribute('data-sidebartype', 'mini-sidebar');
        } else {
          element.setAttribute(
            'data-sidebartype',
            this.state.settings[0].sidebartype
          );
        }
        break;

      default:
    }
  }
  /*--------------------------------------------------------------------------------*/
  /*Life Cycle Hook                                                                 */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    window.removeEventListener('load', this.updateDimensions);
    window.removeEventListener('resize', this.updateDimensions);
  }
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes default(LIGHT) THEME to DARK COLOR:-                   */
  /*--------------------------------------------------------------------------------*/
  darkTheme = (a) => {
    if (a.target.checked) {
      let darktheme = JSON.parse(JSON.stringify(this.state.settings));
      darktheme[0].theme = 'dark';
      this.setState({ settings: darktheme });
    } else {
      let lighttheme = JSON.parse(JSON.stringify(this.state.settings));
      lighttheme[0].theme = 'light';
      this.setState({ settings: lighttheme });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes Default(FULL) LAYOUT to BOXED LAYOUT                   */
  /*--------------------------------------------------------------------------------*/
  boxedTheme = (b) => {
    if (b.target.checked) {
      let boxtheme = JSON.parse(JSON.stringify(this.state.settings));
      boxtheme[0].boxed = 'boxed';
      this.setState({ settings: boxtheme });
    } else {
      let fulltheme = JSON.parse(JSON.stringify(this.state.settings));
      fulltheme[0].boxed = 'full';
      this.setState({ settings: fulltheme });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes Default(ltr) DIRECTION to rtl DIRECTION                   */
  /*--------------------------------------------------------------------------------*/
  rtl = (h) => {
    if (h.target.checked) {
      let rtl = JSON.parse(JSON.stringify(this.state.settings));
      rtl[0].dir = 'rtl';
      this.setState({ settings: rtl });
    } else {
      let ltr = JSON.parse(JSON.stringify(this.state.settings));
      ltr[0].dir = 'ltr';
      this.setState({ settings: ltr });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes Default(FIXED) POSITION of HEADER to ABSOLUTE POSITION */
  /*--------------------------------------------------------------------------------*/
  headerPosition = (c) => {
    if (c.target.checked) {
      let fixedpos = JSON.parse(JSON.stringify(this.state.settings));
      fixedpos[0].headerpos = 'fixed';
      this.setState({ settings: fixedpos });
    } else {
      let absolutepos = JSON.parse(JSON.stringify(this.state.settings));
      absolutepos[0].headerpos = 'absolute';
      this.setState({ settings: absolutepos });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes Default(FIXED) POSITION of SIDEBAR to ABSOLUTE POSITION*/
  /*--------------------------------------------------------------------------------*/
  sidebarPosition = (d) => {
    if (d.target.checked) {
      let sidebarfixedpos = JSON.parse(JSON.stringify(this.state.settings));
      sidebarfixedpos[0].sidebarpos = 'fixed';
      this.setState({ settings: sidebarfixedpos });
    } else {
      let sidebarabsolutepos = JSON.parse(JSON.stringify(this.state.settings));
      sidebarabsolutepos[0].sidebarpos = 'absolute';
      this.setState({ settings: sidebarabsolutepos });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes NAVBAR BACKGROUND-COLOR from given options             */
  /*--------------------------------------------------------------------------------*/
  navbarbgChange = (e) => {
    let navskin = e.currentTarget.dataset.navbarbg;
    let newsettings = JSON.parse(JSON.stringify(this.state.settings));
    newsettings[0].navbarbg = navskin;
    this.setState({
      settings: newsettings,
    });
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes SIDEBAR-MENU BACKGROUND-COLOR from given options       */
  /*--------------------------------------------------------------------------------*/
  sidebarbgChange = (f) => {
    let sidebarskin = f.currentTarget.dataset.sidebarbg;
    let newsettings = JSON.parse(JSON.stringify(this.state.settings));
    newsettings[0].sidebarbg = sidebarskin;
    this.setState({
      settings: newsettings,
    });
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes LOGO BACKGROUND-COLOR from given options               */
  /*--------------------------------------------------------------------------------*/
  logobgChange = (g) => {
    let logoskin = g.currentTarget.dataset.logobg;
    let newsettings = JSON.parse(JSON.stringify(this.state.settings));
    newsettings[0].logobg = logoskin;
    this.setState({
      settings: newsettings,
    });
  };
  render() {
    const { loadingLoginStatus, profileData, profileDetail, userInfo } =
      this.state;
    const { location } = this.props;
    const isTrainingHub = location.pathname.includes('/training-hub');

    // const mergedProfileContext = {
    //   ...profileData,
    //   ...profileDetail,
    //   ...userInfo,
    //   reload: this.reloadData,
    // };
    if (loadingLoginStatus) return <Loading />;

    /*--------------------------------------------------------------------------------*/
    /* Theme Setting && Layout Options wiil be Change From Here                       */
    /*--------------------------------------------------------------------------------*/
    return (
      <div
        id="main-wrapper"
        dir={this.state.settings[0].dir}
        data-theme={this.state.settings[0].theme}
        data-layout={this.state.settings[0].layout}
        data-sidebartype={this.state.settings[0].sidebartype}
        data-sidebar-position={this.state.settings[0].sidebarpos}
        data-header-position={this.state.settings[0].headerpos}
        data-boxed-layout={this.state.settings[0].boxed}
      >
        {/*--------------------------------------------------------------------------------*/}
        {/* Header                                                                         */}
        {/*--------------------------------------------------------------------------------*/}
        <Header
          data={this.state}
          location={location}
          profileData={this.state.profileData}
          userInfo={this.state.userInfo}
          history={this.props.history}
          toggle={this.toggleMobileNav}
        />
        {/*--------------------------------------------------------------------------------*/}
        {/* Sidebar                                                                        */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* Page Main-Content                                                              */}
        {/*--------------------------------------------------------------------------------*/}
        {/* <div
          className={classnames({
            'trainingHub-wrapper': isTrainingHub,
            'page-wrapper': true,
            'd-block': true,
            'custom-color-page-wrapper': true,
            'custom-page-wrapper-menu-expanded': this.state.isNavOpen,
          })}
          style={{ height: '100%' }}
        >
          <div
            className="page-content container-fluid"
            style={{ maxWidth: '1200px', minHeight: '100vh' }}
          > */}
        {/* <ProfileContextProvider value={mergedProfileContext}>
              <Suspense fallback={<Loading />}>
                <Switch>
                  <Route
                    path={profileEditorRoute.path}
                    component={profileEditorRoute.component}
                  />
                </Switch>
              </Suspense>
            </ProfileContextProvider> */}
        {/* <div className="app">
              <Intercom appID="w5qskjlt" { ...this.state.userIntercom } />
            </div> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    );
  }
}
export default Fulllayout;
