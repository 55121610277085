import axios from 'axios';

import history from '../global/history';
import { encodeCookie, decodeCookie } from '../../api/global/cookieParser';
import { backendAPI } from '../global';

const login = ({ email, password }) => {
  return backendAPI
    .post('/auth/login', {
      email,
      password,
    })
    .then((res) => {
      encodeCookie({
        key: 'token',
        value: res.data.headers && res.data.headers.authorization,
      });
      // Once the auth cookie is set, we'll get the blankLayout to work out
      // where to send the user
      history.push('/');
      return res;
    });
};

export const logout = () => {
  backendAPI
    .post('/appLogout')
    .then(() => {
      encodeCookie({ key: 'token', value: undefined });
      history.push('/authentication/login');
    })
    .catch(() => {
      console.log('Error while logging out');
      history.push('/authentication/login');
    });
};

const getUserDetailOnly = async () => {
  const cookie = decodeCookie();
  const authCookie = (cookie && cookie.token) || '';
  if (authCookie === 'undefined' || !authCookie) {
    return;
  }
  return backendAPI.get('/getUserDetail', {
    headers: { Authorization: authCookie },
  });
};

/**
 * First API to be called when user enters the website (that is not a login/register/forgot password page).
 * Check if there is a token saved in the browser or not. If there is none, API call will fail and user will be
 * redirected to the login page
 * @returns {Promise<AxiosResponse<any> | never>}
 */
const getUserDetail = (route, currentPath) => {
  const cookie = decodeCookie();
  const authCookie = (cookie && cookie.token) || '';
  if (authCookie === 'undefined' || !authCookie) {
    history.push('/authentication/login');
    return;
  }
  return backendAPI
    .get('/getUserDetail', {
      headers: {
        Authorization: authCookie,
      },
    })
    .then((res) => {
      if (res.data.roles === 'admin' || res.data.roles === 'super_admin') {
        if (route !== '/admin') {
          history.push('/admin');
        } else {
          history.push(currentPath);
        }
        return res;
      } else {
        // Navigate initial user to questionnaire
        if (route === '/admin' || route === '/authentication') {
          history.push('/dashboard');
        } else history.push(currentPath);
        window.location = 'https://myaccount.equalution.com/';
        return res;
      }
    })
    .catch(({ response }) => {
      console.log('error in login API ', response);
      history.push('/authentication/login');
    });
};

const getUserProfile = () => {
  return backendAPI.get('/user/get-user-profile');
};

const getUserInfo = () => {
  return backendAPI.get('/user/get-client-detail');
};

const resetPassword = (data, token) => {
  return backendAPI.post('/reset/' + token, data);
};

const forgotPassword = (data) => {
  return backendAPI.post('/forgot-password', data);
};

const validateToken = (data) => {
  return backendAPI.post('/validate-token/' + data);
};

export default {
  login,
  logout,
  getUserDetail,
  getUserProfile,
  getUserInfo,
  resetPassword,
  forgotPassword,
  validateToken,
  getUserDetailOnly,
};
