import React from 'react';
import { Link } from 'react-router-dom';
import {
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  Collapse,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
  Modal,
  ModalBody,
} from 'reactstrap';

import apple_badge from '../../assets/images/mobile/badge_apple.png';
import android_badge from '../../assets/images/mobile/badge_android.png';
import classnames from 'classnames';

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logodarkicon from '../../assets/images/logo-icon.png';
import logolighttext from '../../assets/images/logo-light-text.png';
import aceroLogo from '../../assets/images/Acero-Logo.png';
import userIcon from '../../assets/images/icon/user.png';

import loginAPI from '../../api/authentication/login.js';

import './header.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.showMobilemenu = this.showMobilemenu.bind(this);
    this.sidebarHandler = this.sidebarHandler.bind(this);
    this.state = {
      isOpen: false,
      modal: true,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }
  /*--------------------------------------------------------------------------------*/
  /*To open Search Bar                                                              */
  /*--------------------------------------------------------------------------------*/
  toggleMenu() {
    document.getElementById('search').classList.toggle('show-search');
  }
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleModal(obj) {
    this.setState({
      modal: !this.state.modal,
    });
  }
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  showMobilemenu() {
    document.getElementById('main-wrapper').classList.toggle('show-sidebar');
  }
  sidebarHandler = () => {
    let element = document.getElementById('main-wrapper');
    switch (this.props.data.settings[0].sidebartype) {
      case 'full':
      case 'iconbar':
        element.classList.toggle('mini-sidebar');
        if (element.classList.contains('mini-sidebar')) {
          element.setAttribute('data-sidebartype', 'mini-sidebar');
        } else {
          element.setAttribute(
            'data-sidebartype',
            this.props.data.settings[0].sidebartype
          );
        }
        break;

      case 'overlay':
      case 'mini-sidebar':
        element.classList.toggle('full');
        if (element.classList.contains('full')) {
          element.setAttribute('data-sidebartype', 'full');
        } else {
          element.setAttribute(
            'data-sidebartype',
            this.props.data.settings[0].sidebartype
          );
        }
        break;

      default:
    }
  };

  selectedHeaderCSS = (path) => {
    const { pathname } = this.props.location;
    return `custom-nav-link nav-link ${pathname === path && 'active-nav-link'}`;
  };

  handleLogout = () => {
    loginAPI.logout();
  };

  render() {
    const { data, location, profileData, userInfo } = this.props;
    const { email, id, roles, status } = profileData;
    const name = userInfo.fName + ' ' + userInfo.lName;
    const collapseClass = classnames({
      'float-right': true,
      'ml-auto': status.toLowerCase() == 'initial',
    });
    /*
    const appButtonClasses = classNames({
      'main-class': this.selectedHeaderCSS('/check-in'),
      'activeClass': 'appBtn-bg-color'
    });*/

    const isTrainingHub = location.pathname.includes('/training-hub');

    return (
      <div>
        {/*--------------------------------------------------------------------------------*/}
        {/*Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {status.toLowerCase() != 'initial' && (
          <Modal
            isOpen={this.state.modal}
            toggle={() => {
              this.toggleModal();
            }}
          >
            <ModalBody className="banner-modal">
              <div className="reset-banner-modal-body">
                Get the full Equalution experience on our App
              </div>
              <div className="mt-3">
                <a href="https://apps.apple.com/au/app/equalution/id1456085024">
                  <img className="modal-badge" src={apple_badge} />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.equalution.equalution">
                  <img className="modal-badge" src={android_badge} />
                </a>

                <div className="mt-4 reset-banner-website-nav">
                  <u>
                    <strong
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleModal();
                      }}
                    >
                      Continue
                    </strong>
                  </u>{' '}
                  using the website
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}

        <header
          className="topbar navbarbg"
          data-navbarbg={this.props.data.settings[0].navbarbg}
        >
          <Navbar className={'top-navbar ' + 'navbar-dark'} expand="md">
            <div
              className="navbar-header"
              id="logobg"
              data-logobg={this.props.data.settings[0].logobg}
            >
              {/*--------------------------------------------------------------------------------*/}
              {/* Mobile View Toggler  [visible only after 768px screen]                         */}
              {/*--------------------------------------------------------------------------------*/}
              <a
                className="nav-toggler d-block d-md-none text-white"
                onClick={this.showMobilemenu}
              >
                {/*<i className="ti-menu ti-close" />*/}
              </a>
              {/*--------------------------------------------------------------------------------*/}
              {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
              {/*--------------------------------------------------------------------------------*/}
              <NavbarBrand>
                <Link
                  to={
                    status.toLowerCase() === 'initial' ? '/questionnaire' : '/'
                  }
                >
                  <b className="logo-icon">
                    <img
                      src={logodarkicon}
                      alt="homepage"
                      className="dark-logo"
                    />
                    {/*<img*/}
                    {/*src={logolighticon}*/}
                    {/*alt="homepage"*/}
                    {/*className="light-logo"*/}
                    {/*/>*/}
                  </b>
                  <span className="logo-text">
                    <img
                      src={logolighttext}
                      className="light-logo"
                      alt="homepage"
                    />
                    {isTrainingHub && (
                      <img
                        src={aceroLogo}
                        className="d-none d-lg-inline"
                        alt="Acero"
                      />
                    )}
                  </span>
                </Link>
              </NavbarBrand>
              {/*--------------------------------------------------------------------------------*/}
              {/* Mobile View Toggler  [visible only after 768px screen]                         */}
              {/*--------------------------------------------------------------------------------*/}
              {/*
              <div className="mobile-banner">
                <Button onClick={() => this.toggleModal()}>Use App</Button>
              </div>
              */}
              <a
                className="topbartoggler d-block d-md-none text-white"
                onClick={this.props.toggle}
              >
                <i className="ti-menu" />
              </a>
            </div>

            {/* <div className="mobile-banner">
              <Link
                to="/"
                onClick={(e) => {
                  e.preventDefault();
                  this.toggleModal();
                }}
                className="nav-link"
              >
                Download the <strong>App</strong>
              </Link>
            </div> */}
            {!isTrainingHub && (
              <div className="mobile-banner">
                <Link to="/training-hub" className="nav-link">
                  View Our Workouts in the Training Hub
                </Link>
              </div>
            )}

            <Collapse
              className="navbarbg"
              isOpen={this.props.data.isNavOpen}
              navbar
              data-navbarbg={this.props.data.settings[0].navbarbg}
            >
              <Nav className="float-left" navbar>
                <NavItem>
                  <NavLink
                    href="#"
                    className="d-none d-md-block"
                    onClick={this.sidebarHandler}
                  >
                    <i className="ti-menu" />
                  </NavLink>
                </NavItem>
              </Nav>

              {/*--------------------------------------------------------------------------------*/}
              {/* Start Notifications Dropdown                                                   */}
              {/*--------------------------------------------------------------------------------*/}
              {status.toLowerCase() !== 'initial' && (
                <Nav className="ml-auto float-left" navbar>
                  {!isTrainingHub && (
                    <NavItem className="use-app d-none d-md-block">
                      <Button tag={Link} to="/training-hub">
                        Training Hub
                      </Button>
                    </NavItem>
                  )}
                  <NavItem className="use-app">
                    <Button
                      className="one-button-theme-card__button"
                      onClick={() => this.toggleModal()}
                    >
                      Use App
                    </Button>
                  </NavItem>
                  <NavItem>
                    <Link
                      className={this.selectedHeaderCSS('/meal-plan')}
                      to="/meal-plan"
                    >
                      meal plans
                    </Link>
                  </NavItem>
                </Nav>
              )}
              <Nav className={collapseClass} navbar>
                {/*--------------------------------------------------------------------------------*/}
                {/* Start Profile Dropdown                                                         */}
                {/*--------------------------------------------------------------------------------*/}
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret className="pro-pic">
                    <img
                      src={userIcon}
                      alt="user"
                      className="rounded-circle"
                      width="31"
                    />
                  </DropdownToggle>
                  <DropdownMenu right className="user-dd">
                    <div className="d-flex no-block align-items-center p-3 mb-2 border-bottom">
                      {/*<div className="">
                        <img
                            src="https://via.placeholder.com/150"
                            alt="user"
                            className="rounded"
                            width="80"
                        />
                      </div>*/}
                      <div className="ml-3">
                        <h4 className="mb-0">{name}</h4>
                        <p className="text-muted mb-0">{email}</p>
                        {status.toLowerCase() !== 'initial' && (
                          <Button
                            style={{ backgroundColor: '#F37079' }}
                            className="btn-rounded mt-2"
                            onClick={() => this.props.history.push('/profile')}
                          >
                            View Profile
                          </Button>
                        )}
                      </div>
                    </div>
                    <DropdownItem
                      onClick={() => this.props.history.push('/payment-option')}
                    >
                      <i className="ti-wallet mr-1 ml-1" /> Payment Options
                    </DropdownItem>
                    {/*
                    <DropdownItem className="border-bottom">
                      <i className="ti-email mr-1 ml-1" /> Inbox
                    </DropdownItem>
                    <DropdownItem className="border-bottom">
                      <i className="ti-settings mr-1 ml-1" /> Account Settings
                    </DropdownItem>
                    */}
                    <DropdownItem onClick={this.handleLogout}>
                      <i className="fa fa-power-off mr-1 ml-1" /> Logout
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/*--------------------------------------------------------------------------------*/}
                {/* End Profile Dropdown                                                           */}
                {/*--------------------------------------------------------------------------------*/}
              </Nav>
            </Collapse>
          </Navbar>
        </header>
      </div>
    );
  }
}
export default Header;
