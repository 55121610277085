import React, { lazy } from 'react';

const EditPermissions = lazy(() =>
  import('../admin-views/permissions/EditPermissions')
);
const ListCustomIngredients = lazy(() =>
  import('../admin-views/customIngredients/ListCustomIngredients.jsx')
);
const AdminDashboardContainer = lazy(() =>
  import('../admin-views/dashboard/containers/AdminDashboardContainer')
);
const AdminProfileContainer = lazy(() =>
  import(
    '../admin-views/dashboard/adminProfile/container/AdminProfileContainer'
  )
);
const ClientsContainer = lazy(() =>
  import('../admin-views/clients/containers/ClientsContainer')
);
const InitialStrategyContainer = lazy(() =>
  import('../admin-views/initialStrategy/containers/InitialStrategyContainer')
);
const DietaryExclusionsContainer = lazy(() =>
  import(
    '../admin-views/dietaryExclusions/containers/DietaryExclusionsContainer'
  )
);
const HighLightedMealsContainer = lazy(() =>
  import('../admin-views/highLightedMeals/containers/HighLightedMealsContainer')
);
const FoodTagsContainer = lazy(() =>
  import('../admin-views/foodTags/containers/FoodTagsContainer')
);
const MealTagsContainer = lazy(() =>
  import('../admin-views/mealTags/containers/MealTagsContainer')
);
const ProductsContainer = lazy(() =>
  import('../admin-views/products/containers/ProductsContainer')
);

const FoodTagsCategoryContainer = lazy(() =>
  import('../admin-views/foodTagsCategory/containers/FoodTagsCategoryContainer')
);
const BrandedIngredientsContainer = lazy(() =>
  import(
    '../admin-views/brandedIngredients/containers/BrandedIngredientsContainer.jsx'
  )
);
const GenericIngredientsContainer = lazy(() =>
  import(
    '../admin-views/genericIngredients/containers/GenericIngredientsContainer.jsx'
  )
);
const MealsContainer = lazy(() =>
  import('../admin-views/meals/containers/MealsContainer')
);
const LogsContainer = lazy(() =>
  import('../admin-views/logs/containers/LogsContainer')
);
const AppleIAPContainer = lazy(() =>
  import('../admin-views/appleIAP/containers/AppleIAPContainer')
);
const CheckinAnalyticsContainer = lazy(() =>
  import('../admin-views/checkinAnalytics/containers/CheckinAnalyticsContainer')
);
const MealPlanAnalyticsContainer = lazy(() =>
  import(
    '../admin-views/mealPlanAnalytics/containers/mealPlanAnalyticsContainer'
  )
);
const WaterIntakeGoalContainer = lazy(() =>
  import('../admin-views/waterIntakeGoal/WaterIntakeGoalContainer')
);
const GlobalConfigContainer = lazy(() =>
  import('../admin-views/globalConfig/GlobalConfigContainer')
);

const AdminThemeRoutes = [
  {
    collapse: true,
    visible: false,
    state: 'dashboard',
    path: '/admin/dashboard',
    name: 'Filters',
    mini: 'B',
    permissions: 'basic_admin',
    roles: 'super_admin',
    icon: 'mdi mdi-view-dashboard',
    child: [
      {
        path: '/admin/dashboard/newClients',
        name: 'New Clients',
        mini: 'B',
        icon: 'mdi mdi-view-dashboard',
        filter: 'newClients',
        permissions: 'basic_admin',
        roles: 'super_admin',
        component: AdminDashboardContainer,
      },
      {
        path: '/admin/dashboard/userExtras',
        name: 'Clients With Add-Ons',
        mini: 'B',
        icon: 'mdi mdi-view-dashboard',
        filter: 'hasConsumableProduct',
        permissions: 'basic_admin',
        roles: 'super_admin',
        component: AdminDashboardContainer,
      },
      {
        path: '/admin/dashboard/checkInToday',
        name: 'Check In Today',
        mini: 'B',
        icon: 'mdi mdi-view-dashboard',
        filter: 'checkInToday',
        permissions: 'basic_admin',
        roles: 'super_admin',
        component: AdminDashboardContainer,
      },
      {
        path: '/admin/dashboard/preapproved',
        name: 'Pre-Approved Meal Plan',
        mini: 'B',
        icon: 'mdi mdi-view-dashboard',
        filter: 'preapproved',
        permissions: 'basic_admin',
        roles: 'super_admin',
        component: AdminDashboardContainer,
      },
      {
        path: '/admin/dashboard/international',
        name: 'International Clients',
        mini: 'B',
        icon: 'mdi mdi-view-dashboard',
        filter: 'internationalClients',
        permissions: 'basic_admin',
        roles: 'super_admin',
        component: AdminDashboardContainer,
      },
      {
        path: '/admin/dashboard/taggedUsers',
        name: 'Tagged Clients',
        mini: 'B',
        icon: 'mdi mdi-view-dashboard',
        filter: 'taggedClients',
        permissions: 'basic_admin',
        roles: 'super_admin',
        component: AdminDashboardContainer,
      },
      {
        path: '/admin/dashboard/all',
        name: 'All Filters',
        mini: 'B',
        icon: 'mdi mdi-view-dashboard',
        permissions: 'basic_admin',
        roles: 'super_admin',
        filter: 'all',
        component: AdminDashboardContainer,
      },
    ],
  },
  {
    path: '/admin/clients',
    name: 'Clients',
    state: 'clients',
    icon: 'icon-people',
    mini: 'B',
    permissions: 'basic_admin',
    roles: 'super_admin',
    component: ClientsContainer,
    exact: true,
  },
  {
    collapse: true,
    visible: false,
    path: '/admin/data',
    name: 'Data',
    state: 'data',
    permissions: 'basic_admin',
    roles: 'super_admin',
    icon: 'mdi mdi-gauge',
    child: [
      {
        path: '/admin/data/branded-ingredients',
        name: 'Branded Ingredients',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        permissions: 'data_ingredients',
        roles: 'super_admin',
        component: BrandedIngredientsContainer,
      },
      {
        path: '/admin/data/generic-ingredients',
        name: 'Generic Ingredients',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        permissions: 'data_ingredients',
        roles: 'super_admin',
        component: GenericIngredientsContainer,
      },
      {
        path: '/admin/data/customingredients',
        name: 'Custom Ingredients',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        permissions: 'data_custom_ingredients',
        roles: 'super_admin',
        component: ListCustomIngredients,
        exact: true,
      },
      {
        path: '/admin/data/meals',
        name: 'Meals',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        permissions: 'data_meals',
        roles: 'super_admin',
        component: MealsContainer,
      },
      {
        path: '/admin/data/dietaryExclusions',
        name: 'Dietary Exclusions',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        permissions: 'data_dietary_exclusions',
        roles: 'super_admin',
        component: DietaryExclusionsContainer,
      },
      {
        path: '/admin/data/highlightedMeals',
        name: 'Highlighted Meals',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        permissions: 'basic_admin',
        roles: 'super_admin',
        component: HighLightedMealsContainer,
      },
      {
        path: '/admin/data/foodTags',
        name: 'Food Tags',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        permissions: 'data_food_tags',
        roles: 'super_admin',
        component: FoodTagsContainer,
      },
      {
        path: '/admin/data/categoryFoodTags',
        name: 'Food Tags Category',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        permissions: 'data_food_tags_category',
        roles: 'super_admin',
        component: FoodTagsCategoryContainer,
      },
      {
        path: '/admin/data/mealTags',
        name: 'Meal Tags',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        permissions: 'data_meal_tags',
        roles: 'super_admin',
        component: MealTagsContainer,
      },
    ],
  },
  {
    collapse: true,
    visible: false,
    path: '/admin/tools',
    name: 'Tools',
    state: 'tools',
    icon: 'mdi mdi-ruler',
    permissions: 'basic_admin',
    roles: 'super_admin',
    child: [
      {
        path: '/admin/tools/initialStrategy',
        name: 'Initial Strategy',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        permissions: 'tools_initial_strategy',
        roles: 'super_admin',
        component: InitialStrategyContainer,
      },
      {
        path: '/admin/tools/waterIntakeGoal',
        name: 'Water Intake Goal',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        permissions: 'admin_tools_water_intake_goal',
        roles: 'super_admin',
        component: WaterIntakeGoalContainer,
      },
      {
        path: '/admin/tools/audit',
        name: 'Administrative Audit',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        permissions: 'tools_administrative_audit',
        roles: 'super_admin',
        component: LogsContainer,
      },
    ],
  },
  {
    collapse: true,
    visible: false,
    path: '/admin/adminTools',
    name: 'Admin Tools',
    state: 'admintools',
    icon: 'icon-screen-desktop',
    permissions: 'basic_admin',
    roles: 'super_admin',
    child: [
      {
        path: '/admin/adminTools/analytics',
        name: 'Checkin Analytics',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        permissions: 'admin_tools_checkin_analytics',
        roles: 'super_admin',
        component: CheckinAnalyticsContainer,
      },
      {
        path: '/admin/adminTools/applePayments',
        name: 'Apple In-App Purchases',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        permissions: 'admin_tools_apple_in_app_purchases',
        roles: 'super_admin',
        component: AppleIAPContainer,
      },
      {
        path: '/admin/adminTools/approvalAnalytics',
        name: 'Meal Plan Approvals',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        permissions: 'admin_tools_meal_plan_approvals',
        roles: 'super_admin',
        component: MealPlanAnalyticsContainer,
      },
      {
        path: '/admin/adminTools/products',
        name: 'Products',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        permissions: 'admin_tools_products',
        roles: 'super_admin',
        component: ProductsContainer,
      },
      {
        path: '/admin/adminTools/globalConfig',
        name: 'Global Configurations',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        permissions: 'admin_tools_products',
        roles: 'super_admin',
        component: GlobalConfigContainer,
      },
    ],
  },
];

export const clientRoute = {
  path: '/admin/clients/:profileId',
  name: 'Clients',
  state: 'clients',
  icon: 'icon-people',
  mini: 'B',
  component: ClientsContainer,
};

export const profileEditorRoute = {
  path: '/admin/account',
  name: 'Account',
  state: 'dashboardpages',
  icon: 'mdi mdi-account',
  mini: 'B',
  component: AdminProfileContainer,
};

export const permissionsEditorRoute = {
  path: '/admin/adminTools/permissions/:id',
  name: 'Permissions',
  state: 'permissions',
  icon: 'icon-people',
  mini: 'B',
  component: EditPermissions,
  exact: true,
};

export default AdminThemeRoutes;
