import Fulllayout from '../layouts/fulllayout.jsx';
import Blanklayout from '../layouts/blanklayout.jsx';
import Adminlayout from '../layouts/adminlayout';

var indexRoutes = [
  { path: '/authentication', name: 'Authentication', component: Blanklayout },
  { path: '/admin', name: 'Admin Portal', component: Adminlayout },
  { path: '/', name: 'Dashboard', component: Fulllayout },
];

export { indexRoutes };
