import { lazy } from 'react';
const Login = lazy(() => import('./../admin-views/authentication/login/login'));
const Register = lazy(() =>
  import('../admin-views/authentication/register_OLD/components/Register')
);
const ForgotPasswordlayout = lazy(() =>
  import('./../admin-views/authentication/login/forgotpassword.jsx')
);

const authRoutes = [
  { path: '/authentication/login', name: 'Login', component: Login },
  {
    path: '/authentication/forgot-password',
    name: 'Forgot Password',
    icon: 'mdi mdi-account-convert',
    component: ForgotPasswordlayout,
  },
  {
    path: '/authentication/register',
    name: 'Register',
    icon: 'mdi mdi-account-plus',
    component: Register,
  },
];
export default authRoutes;
