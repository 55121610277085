import axios from 'axios';
import { decodeCookie } from './cookieParser';

const backendDomain = process.env.REACT_APP_BACKEND_DOMAIN;

const backendAPI = axios.create({
  baseURL: backendDomain,
  headers: {
    'Content-Type': 'application/json',
  },
});

backendAPI.interceptors.request.use(function (config) {
  const cookie = decodeCookie();
  config.headers = {
    Authorization: (cookie && cookie.token) || '',
  };
  return config;
});

export { backendAPI };
