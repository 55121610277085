import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { indexRoutes } from './routes/index.jsx';
import { Route, Switch } from 'react-router-dom';
import { Router } from 'react-router-dom';

import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';

import './api/global/index.js';
import history from './api/global/history';

import './assets/scss/style.css';
import './assets/scss/global.scss';
import './assets/scss/grid.scss';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import loginAPI from './api/authentication/login.js';

const store = configureStore();

const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_API_KEY,
});

function App() {
  const [userId, setUserId] = useState();

  useEffect(() => {
    (async () => {
      const userDetails = await loginAPI.getUserDetailOnly();
      if (userDetails) {
        setUserId(userDetails.data.id);
      }
    })();
  }, []);

  return (
    <Router history={history}>
      <Provider store={store}>
        <OptimizelyProvider
          optimizely={optimizely}
          user={{
            id: `${userId}`,
            attributes: {
              userIdentifier: `${userId}`,
              employee: true,
            },
          }}
        >
          <Switch>
            {indexRoutes.map((prop, key) => {
              return (
                <Route path={prop.path} key={key} component={prop.component} />
              );
            })}
          </Switch>
        </OptimizelyProvider>
      </Provider>
    </Router>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
