const initialState = {
  workoutType: undefined,
  weekData: undefined,
  selectedIndex: 0,
};

export default (state, action) => {
  if (!state) return initialState;

  switch (action.type) {
    case 'TRAININGHUB_WORKOUT_TYPE': {
      const { workoutType } = action;
      return {
        ...state,
        workoutType,
      };
    }

    case 'TRAININGHUB_WORKOUT_WEEKDATA': {
      const { weekData, finalWorkoutIndex, selectedIndex } = action;
      return {
        ...state,
        weekData,
        finalWorkoutIndex: finalWorkoutIndex || state.finalWorkoutIndex,
        selectedIndex: selectedIndex,
      };
    }

    default: {
      return state;
    }
  }
};
