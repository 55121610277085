import React, { PureComponent } from 'react';

import './rc.scss';

export default class Loading extends PureComponent {
  render() {
    return (
      <div
        className={`loading-component ${
          this.props.modalLoading ? 'modal-loading' : ''
        }`}
      >
        <i className="fas fa-spinner fa-spin" />
      </div>
    );
  }
}
