import { compose, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk-recursion-detect';
import createRootReducer from '.';

export default function configureStore(preloadedState) {
  let composeEnhancers;

  // eslint-disable-next-line no-underscore-dangle
  if (
    process.env.NODE_ENV !== 'production' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) {
    // eslint-disable-next-line no-underscore-dangle
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  } else {
    composeEnhancers = compose;
  }

  const store = createStore(
    createRootReducer(),
    preloadedState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );

  return store;
}
